import React, { useState } from 'react'
import { BubbleLoader } from '@deal/components'
import { useExpertForRatingQuery } from './ExpertForRating.generated'
import { TipExpert } from '../TipExpert'
import { ExpertShowcaseModal } from '../ExpertShowcaseModal'
import { ExpertRatingContent } from '../ExpertRatingContent'

interface ExpertRatingProps {
  onCloseModal: () => void
  onPathCompleted: () => void
  expertStarRating?: number
  expertId: string
  entryPoint?: string
}

const ExpertRatingModal: React.FC<React.PropsWithChildren<ExpertRatingProps>> = ({
  onCloseModal,
  onPathCompleted,
  expertStarRating,
  entryPoint = 'expert-rating-modal',
  expertId
}) => {
  const [isTipping, setIsTipping] = useState(false)
  const { data, refetch, loading } = useExpertForRatingQuery({
    variables: {
      vanityIdOrId: expertId
    },
    notifyOnNetworkStatusChange: true //trigger loading during refetch
  })

  const expert = data?.findExpert

  const handlePathCompleted = () => {
    refetch().then(response => {
      const expert = response.data.findExpert
      const lead = expert?.existingLeadForConsumer
      if (
        lead?.qualityConversation &&
        (lead?.consumerReview?.rating ?? 0) >= 4 &&
        !expert?.hasReceivedTipFromUser
      ) {
        setIsTipping(true)
        return
      }
      onPathCompleted()
    })
  }

  const handleCloseModal = () => {
    if (isTipping) {
      onPathCompleted()
    }
    onCloseModal()
  }

  if (expert) {
    return (
      <ExpertShowcaseModal title="Leave a review" onCloseModal={handleCloseModal} expert={expert}>
        {loading ? (
          <BubbleLoader />
        ) : isTipping ? (
          <TipExpert
            expert={expert}
            leadId={expert.existingLeadForConsumer!.id}
            onCompleted={handleCloseModal}
          />
        ) : (
          <ExpertRatingContent
            onPathCompleted={handlePathCompleted}
            expertStarRating={expertStarRating}
            entryPoint={entryPoint}
            expertId={expert.id}
          />
        )}
      </ExpertShowcaseModal>
    )
  }

  return null
}

export default ExpertRatingModal
