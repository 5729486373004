import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useCreatePath } from '@deal/path-flow'
import { usePathPageTrackingCodes } from '#src/app/services/path'
import StandalonePath from '#src/app/components/Path/StandalonePath'

interface ExpertRatingProps {
  onPathCompleted: () => void
  expertStarRating?: number
  expertId: string
  entryPoint?: string
}

const ExpertRatingContent: React.FC<React.PropsWithChildren<ExpertRatingProps>> = ({
  onPathCompleted,
  expertStarRating,
  entryPoint = 'expert-rating-modal',
  expertId
}) => {
  const pageTrackingCodes = usePathPageTrackingCodes()

  const [defaultSubmitToken, setDefaultSubmitToken] = useState<string | undefined>()

  const [createPath] = useCreatePath({
    onPathCreated: ({ pathGraphFlow }) => {
      if (pathGraphFlow) {
        setDefaultSubmitToken(pathGraphFlow?.submitToken)
      } else {
        toast.error('There was an error reviewing that expert!')
      }
    },
    onPathCompleted: () => {
      onPathCompleted()
    }
  })
  useEffect(() => {
    createPath({
      timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
      consumerReviewForExpert: {
        expertId: expertId,
        pathSelector: {
          pathSchemaSlug: 'cre-expert-review'
        },
        expertStarRating
      },
      trackingCodes: {
        ctaName: entryPoint,
        pageKey: pageTrackingCodes.pageKey,
        originalPageContext: pageTrackingCodes.originalPageContext,
        originalPageUrl: pageTrackingCodes.originalPageUrl,
        sourceKey: entryPoint
      }
    })
  }, [])

  if (defaultSubmitToken) {
    return (
      <StandalonePath
        pathPlacement="modal"
        onReturnToPreviousContext={() => {
          onPathCompleted()
        }}
        defaultSubmitToken={defaultSubmitToken}
      />
    )
  }

  return null
}

export { ExpertRatingContent }
