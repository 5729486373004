import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import classNames from 'classnames'
import { Button, Heading, Text, TextField } from '@deal/bluxome'
import { BusinessUserForTipExpertFragment, useTipExpertMutation } from './TipExpert.generated'
import styles from './styles.css'

interface TipExpertProps {
  expert: BusinessUserForTipExpertFragment
  leadId: string
  onCompleted: () => void
}

export const TipExpert: React.FC<TipExpertProps> = ({ expert, leadId, onCompleted }) => {
  const [selectedValue, setSelectedValue] = useState(-1)
  const [customTip, setCustomTip] = useState('')
  const history = useHistory()

  const [tipExpert, { loading }] = useTipExpertMutation({
    variables: {
      input: {
        leadId: leadId,
        price: {
          amount: selectedValue === 0 ? parseFloat(customTip).toFixed(2) : selectedValue.toFixed(2),
          currency: 'USD'
        }
      }
    },
    onCompleted(data) {
      const orderId = data.createExpertConsultationFeeOrder.curatedOrder.orderId
      if (orderId) {
        history.push(`/checkout/${orderId}/payment`)
        onCompleted()
      } else {
        toast.error('There was error submitting tip, please try again')
      }
    }
  })

  const canSubmit = selectedValue > 0 || customTip.length > 0
  return (
    <div className={styles.tip}>
      <Heading size="h4">{expert.displayName}</Heading>
      <Text>100% of your tip goes to {expert.shortName}!</Text>
      <Text>Thank your expert for their time and expertise by leaving an optional tip!</Text>

      <div className={styles.toggleGroup}>
        {[20, 15, 10, 0].map(amount => {
          const isSelected = selectedValue === amount
          const amountText = amount === 0 ? 'Custom' : `$${amount}`
          return (
            <button
              key={`tip-amount-${amount}`}
              className={classNames(styles.toggle, { [styles.selected]: isSelected })}
              onClick={() => setSelectedValue(amount)}
            >
              <Text color={isSelected ? 'learn-blue-500' : 'neutrals-black'}>{amountText}</Text>
            </button>
          )
        })}
      </div>
      {selectedValue === 0 && (
        <TextField
          aria-label="Custom tip amount"
          type="number"
          value={customTip}
          onChange={setCustomTip}
          placeholder="0.00"
          unit={{ label: '$', position: 'prefix' }}
        />
      )}
      <Button
        color="neutral-dark"
        size="large"
        isDisabled={!canSubmit}
        isLoading={loading}
        onPress={() => tipExpert()}
      >
        Submit
      </Button>
    </div>
  )
}
