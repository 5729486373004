import React, { PropsWithChildren } from 'react'
import { Image } from '@deal/components'
import { Heading, HeadlessModal, useModalTrigger } from '@deal/bluxome'
import { BusinessUserForExpertShowcaseModalFragment } from './ExpertShowcaseModal.generated'
import { ExpertAvatar } from '../ExpertAvatar'
import styles from './styles.css'

interface ExpertRatingProps extends PropsWithChildren {
  onCloseModal: () => void
  expert: BusinessUserForExpertShowcaseModalFragment
  title: string
}

const ExpertShowcaseModal: React.FC<React.PropsWithChildren<ExpertRatingProps>> = ({
  onCloseModal,
  expert,
  title,
  children
}) => {
  const { state, overlayProps } = useModalTrigger({
    isOpen: true,
    onOpenChange: isOpen => {
      if (!isOpen) {
        onCloseModal()
      }
    }
  })

  const businessUser = expert
  const backgroundImage =
    expert.department.topLevelCategory.categoryAssetRevision?.bannerImages?.images.at(0)?.image.url

  return (
    <HeadlessModal overlayProps={overlayProps} state={state} width="large">
      {({ titleProps }) => (
        <div className={styles.modalContent}>
          <div {...titleProps} className={styles.header}>
            <Heading size="h4">{title}</Heading>
            <div className={styles.expertShowcase}>
              {backgroundImage && (
                <div className={styles.backgroundImage}>
                  <Image src={backgroundImage} size="100%" />
                </div>
              )}
              <ExpertAvatar
                expert={businessUser}
                size="extra-large"
                showRating={false}
                borderContrast
              />
            </div>
          </div>
          <div className={styles.details}>{children}</div>
        </div>
      )}
    </HeadlessModal>
  )
}

export { ExpertShowcaseModal }
