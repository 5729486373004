// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type BusinessUserForTipExpertFragment = { __typename: 'BusinessUser', id: any, displayName: string, shortName: string };

export type TipExpertMutationVariables = Types.Exact<{
  input: Types.CreateExpertConsultationFeeOrderInput;
}>;


export type TipExpertMutation = { __typename: 'Mutation', createExpertConsultationFeeOrder: { __typename: 'CreateExpertConsultationFeeOrderPayload', curatedOrder: { __typename: 'SimpleCuratedOrder', id: any, orderId: any } } };

export const BusinessUserForTipExpertFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BusinessUserForTipExpert"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"shortName"}}]}}]} as unknown as DocumentNode;
export const TipExpertDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"TipExpert"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateExpertConsultationFeeOrderInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createExpertConsultationFeeOrder"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"curatedOrder"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"orderId"}}]}}]}}]}}]} as unknown as DocumentNode;
export type TipExpertMutationFn = Apollo.MutationFunction<TipExpertMutation, TipExpertMutationVariables>;
export type TipExpertComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TipExpertMutation, TipExpertMutationVariables>, 'mutation'>;

    export const TipExpertComponent = (props: TipExpertComponentProps) => (
      <ApolloReactComponents.Mutation<TipExpertMutation, TipExpertMutationVariables> mutation={TipExpertDocument} {...props} />
    );
    

/**
 * __useTipExpertMutation__
 *
 * To run a mutation, you first call `useTipExpertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTipExpertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tipExpertMutation, { data, loading, error }] = useTipExpertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTipExpertMutation(baseOptions?: Apollo.MutationHookOptions<TipExpertMutation, TipExpertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TipExpertMutation, TipExpertMutationVariables>(TipExpertDocument, options);
      }
export type TipExpertMutationHookResult = ReturnType<typeof useTipExpertMutation>;
export type TipExpertMutationResult = Apollo.MutationResult<TipExpertMutation>;
export type TipExpertMutationOptions = Apollo.BaseMutationOptions<TipExpertMutation, TipExpertMutationVariables>;